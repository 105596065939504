@import '../base/breakpoints';
/* Estilos de base para projetos da Din digital.(recomenda-se o use do normalize junto à estes estilos -> https://necolas.github.io/normalize.css/) */

/*As regras sugeridas por esses estilos, seguem padrões seguidos pelo time de front da Din digital.*/

/*Estilos do documento*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  // Flex para o <app-root> ficar sempre full height
  display: flex;
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
}

app-root {
  width: 100%;
}

/*Estilos de texto*/
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

/*Estilos de listas*/
ol,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

/*Estilos para links*/
a {
  color: inherit;
  text-decoration: none;
}

a:not([title]),
a[title=''],
a:not([target]) a[target=''] {
  content: 'Sua tag <a> deve possuir o atributo title e target';
  outline: solid red 3px;
}

/*Estilos para deixar links para páginas, em novas abas, mais seguros*/
a[target='_blank']:not([rel='noreferrer noopener']) {
  content: 'Sua tag <a> deve possuir o atributo rel com os valores noreferrer noopener';
  outline: solid red 3px;
}

/*Estilos para imagens*/
img {
  display: block;
  max-width: 100%;
}

/* Verifica se as imagens possuem os atributos mínimos definidos pelo time de front-end  */
img:not([alt]),
img[alt=''],
img:not([width]),
img[width=''],
img:not([height]),
img[height=''],
img:not([loading]),
img:not([loading='lazy']) {
  content: 'Sua tag <img> não possui algum dos seguintes atributos: alt, width, height ou loading';
  outline: solid red 6px;
  outline-offset: -6px;
}

/* Reset para os estilos dos botões */
button {
  background: none;
  border: none;
  padding: 0;
}

button:not(:disabled) {
  cursor: pointer;
}

button:not([type]),
button[type=''] {
  content: 'Sua tag <button> deve possuir o atributo type';
  outline: solid red 3px;
}

/*Estilos úteis para esconder labels visualmente, porém ainda visível para screen readers*/
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 14px !important;
}

.cdk-overlay-backdrop {
  background: rgba(0, 59, 156, 0.36);
  backdrop-filter: blur(15px);
}

/*Fim dos estilos base -------------------------------------------*/
