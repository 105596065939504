:root {
  --font-size-base: 16px;
  --font-primary: 'Mulish', sans-serif;
}

@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon/fonts/icomoon.eot?i9ai2l');
  src: url('/assets/fonts/icomoon/fonts/icomoon.eot?i9ai2l#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/icomoon/fonts/icomoon.ttf?i9ai2l') format('truetype'),
    url('/assets/fonts/icomoon/fonts/icomoon.woff?i9ai2l') format('woff'),
    url('/assets/fonts/icomoon/fonts/icomoon.svg?i9ai2l#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: '\e909';
}
.icon-info-circle:before {
  content: '\e908';
}
.icon-checkbox-tick:before {
  content: '\e901';
}
.icon-arrow-select:before {
  content: '\e907';
}
.icon-information:before {
  content: '\e900';
}
.icon-arrow-left:before {
  content: '\e902';
}
.icon-card-tick:before {
  content: '\e903';
}
.icon-barcode:before {
  content: '\e904';
}
.icon-phone-sound:before {
  content: '\e905';
}
.icon-arrow-down:before {
  content: '\e906';
}
