.dd-m-title {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: rem(18px);
  line-height: 150%;
  letter-spacing: 0.01em;
  color: var(--color-dark);

  &.--has-limit {
    max-width: 370px;
  }
}
