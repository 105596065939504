.dd-l-subpage {
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $desk-sm) {
    height: unset;
  }

  form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    app-button {
      margin-top: auto;
    }
  }
}
