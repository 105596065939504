@import '../../../scss/base/mixins/rem';
@import '../../../scss/base/animations/loading-spinner';

.dd-m-button {
  width: 100%;
  display: inline-block;
  text-align: center;
  padding: 16px 40px;
  position: relative;
  color: var(--color-light-tint);
  background-color: var(--color-primary);
  transition: background-color 0.25s ease-in-out;
  font-size: rem(16px);
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.01em;
  border-radius: 8px;

  &:hover,
  &:focus {
    background-color: var(--color-primary-tint);
  }

  &.is-loading {
    pointer-events: none;
    color: transparent;

    &::after {
      box-sizing: border-box;
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border: 3px solid var(--color-light-tint);
      border-top-color: transparent;
      border-radius: 50%;
      animation: loading-spinner 1s ease infinite;
      background-color: inherit;
    }
  }

  &:disabled {
    pointer-events: none;
    background-color: var(--color-medium-tint);
  }
}
