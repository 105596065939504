.dd-m-title-banner {
  font-weight: 800;
  line-height: 125%;
  letter-spacing: 0.01em;
  color: var(--color-light-tint);
  font-size: clamp(rem(24px), 7.5vw, rem(40px));
  margin: 0;

  @media screen and (min-width: $tablet) {
    max-width: 700px;
    margin: 0 auto;
  }

  span {
    display: table;
    margin: 0 auto;

    @media screen and (min-width: $tablet) {
      display: inline;
      margin: unset;
    }

    @media screen and (min-width: $desk-sm) {
      display: table;
    }
  }
}
