.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-34 {
  margin-bottom: 34px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-48 {
  margin-bottom: 40px;
}

.mb-60 {
  margin-bottom: 60px;
}

// Tablet
.mb-md-32 {
  @media screen and (min-width: $tablet) {
    margin-bottom: 32px;
  }
}

// Desktop
.mb-lg-0 {
  @media screen and (min-width: $desk-sm) {
    margin-bottom: 0px;
  }
}

.mb-lg-52 {
  @media screen and (min-width: $desk-sm) {
    margin-bottom: 52px;
  }
}

.mb-lg-56 {
  @media screen and (min-width: $desk-sm) {
    margin-bottom: 56px;
  }
}

//-------------------------------

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}
