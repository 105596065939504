.dd-m-paragraph {
  font-weight: 600;
  font-size: rem(18px);
  line-height: 150%;
  letter-spacing: 0.01em;
  color: var(--color-dark-tint);

  strong {
    font-weight: 700;
    color: var(--color-dark);
  }

  span {
    display: block;
  }

  &.--dark {
    color: var(--color-dark);
  }

  &.--center {
    text-align: center;
  }
}
