.dd-l-page {
  width: 100%;
  height: 100%;
  padding: 40px 24px 24px;
  position: relative;
  display: flex;
  justify-content: center;

  @media screen and (min-width: $desk-sm) {
    padding: 0;
    padding-bottom: 142px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 666px;
    flex: 1;

    @media screen and (min-width: $desk-sm) {
      max-width: 384px;
      margin: auto;
    }
  }

  &__footer {
    margin-top: auto;
  }

  &__floating-element-lg {
    @media screen and (min-width: $desk-sm) {
      position: absolute;
      bottom: 64px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.--not-vertical-centered {
    @media screen and (min-width: $desk-sm) {
      padding: 70px 0 32px;
      .dd-l-page__content {
        margin: unset;
      }
    }
  }
}
