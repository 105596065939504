@use 'sass:math';

.dd-l-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
}

@for $i from 1 through 12 {
  .dd-l-col-#{$i} {
    flex-basis: math.div(100%, 12) * $i;
    max-width: math.div(100%, 12) * $i;
    padding: 0 8px;
  }
}

@for $i from 1 through 12 {
  @media screen and (min-width: $tablet) {
    .dd-l-col-md-#{$i} {
      flex-basis: math.div(100%, 12) * $i;
      max-width: math.div(100%, 12) * $i;
      padding: 0 8px;
    }
  }
}

@for $i from 1 through 12 {
  @media screen and (min-width: $desk-sm) {
    .dd-l-col-lg-#{$i} {
      flex-basis: math.div(100%, 12) * $i;
      max-width: math.div(100%, 12) * $i;
      padding: 0 8px;
    }
  }
}
