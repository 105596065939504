:root {
  // Cores primárias:
  --color-primary: #0053d4;
  --color-primary-shade: #003b9c;
  --color-primary-tint: #0062ff;
  --color-primary-tint-rgb: 0, 98, 255;

  // Cores secundárias:
  --color-secondary: #00cc99;
  --color-secondary-shade: #00b084;
  --color-secondary-tint: #00edb2;

  // Cores terceárias:
  --color-tertiary: #00e1e5;
  --color-tertiary-shade: #00d3d6;
  --color-tertiary-tint: #62f5f5;

  // Cores escuras:
  --color-dark: #495057;
  --color-dark-shade: #343a40;
  --color-dark-tint: #727980;

  // Cores médias:
  --color-medium: #ced4da;
  --color-medium-shade: #adb5bd;
  --color-medium-tint: #dee2e6;

  // Cores claras:
  --color-light: #f3f5f7;
  --color-light-shade: #e9ecef;
  --color-light-tint: #ffffff;

  // Cores de alerta:
  --color-danger: #f64f77;
  --color-danger-shade: #d64568;
  --color-danger-tint: #ff7092;
}
